@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
:root {
  --red: #db5b5a;
  --blue: #172f64;
  --grey: rgba(0, 0, 0, 0.6);
}
body {
  overflow-x: hidden;
  min-height: 100vh;
}

.nav-back {
  position: fixed;
  top: 0;
  min-height: 60px;
  width: 100vw;
  background-color: white;
  z-index: 999;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  z-index: 999;
  margin-bottom: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  text-decoration: none;
  color: black;
}

.nav-links a:hover {
  color: black;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--red);
  margin: 5px;
  transition: 0.3s all ease;
}

@media screen and (max-width: 1200px) {
  .nav-links {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  nav {
    position: fixed;
    top: 0px;
    width: 80vw;
  }

  .nav-links {
    position: fixed;
    right: 0px;
    height: 100vh;
    top: 0vh;
    padding-top: 60px;
    background-color: rgb(252, 252, 252);
    font-size: 1.1rem;
    display: block;
    flex-direction: column;
    width: 70%;
    transform: translateX(100%);
    transition: transform 0.5s ease;
    border-left: 10px solid var(--red);
  }

  .nav-links a {
  }

  .burger {
    display: block;
    float: right;
    z-index: 999;
  }
}

@media screen and (max-width: 600px) {
  nav {
    width: 90%;
  }
}

.nav-active {
  transform: translateX(0%);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.mainSection {
  margin-top: 60px;
  position: relative;
}

.mainSection-heading {
  margin-top: 80px;
  font-size: 2rem;
  color: #172f64;
  letter-spacing: 1px;
}

.mainSection-subheading {
  margin-top: 30px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #999999;
  letter-spacing: 1px;
}

.mainSection-button {
  margin-top: 80px;
  border: 0;
  font-size: 0.9rem;
  background-color: var(--red);
  border-radius: 4px;
  color: white;
  padding: 10px 25px;
  box-shadow: 0px 3px 3px #c7c7c7;
}

.mainSection-imageContainer {
  position: relative;
  height: 100%;
}

.mainSection-image {
  position: relative;
  left: 50px;
  overflow: hidden;

  height: 100%;
}

.mainSection-bannerContainer {
  position: absolute;

  margin-top: 65px;
  display: flex;
  background-color: rgba(255, 255, 255, 0);
  z-index: 5;
}

.mainSection-banner {
  border-radius: 4px;
  display: flex;
  padding: 35px 100px;
  box-shadow: 45px 32px 80px rgba(0, 0, 0, 0.07),
    23px 16px 34px rgba(0, 0, 0, 0.047), 9px 6.5px 13px rgba(0, 0, 0, 0.035),
    2px 1.5px 4.6px rgba(0, 0, 0, 0.0227);

  min-width: 50vw;
  max-width: 80vw;
  /* min-height: 100px; */
  background-color: rgba(255, 255, 255, 1);
}

@media only screen and (min-width: 1721px) {
  .mainSection {
    position: relative;
  }
  .mainSection-banner {
    min-width: 700px;
  }
}

@media only screen and (min-width: 1595px) {
  .mainSection-banner {
    min-width: 700px;
  }
}

.mainSection-banner > div:nth-child(1) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding-right: 30px;
  margin-right: 30px;
}

.mainSection-bannerHeading {
  letter-spacing: 0.5px;
  color: var(--blue);
  font-size: 1.3rem;
  font-weight: bold;
}

.mainSection-bannerPhone {
  padding-top: 10px;
  font-size: 1.15rem;
  color: var(--grey);
}

.mainSection-bannerCut {
  transform: skewX(-8deg);
  position: relative;
  left: -20px;
  width: 40px;
  border-radius: 4px;
  background-color: white;
}

.reviewSection,
.servicesSection,
.locationSection {
  padding-top: 120px !important;
  margin-bottom: 60px !important;
  position: relative;
  z-index: 2;
}

.reviewSection-heading,
.servicesSection-heading,
.locationSection-heading {
  font-weight: bold;
  font-size: 56px;
  text-align: center;
  color: var(--blue);
  position: relative;
}

.servicedots {
  position: absolute;
  top: 45%;
  left: 82%;
  opacity: 1 !important;
  z-index: 0;
}

.reviewdots {
  position: absolute;
  top: 78%;
  left: -8%;
  opacity: 1 !important;
  z-index: 0;
}

@media only screen and (max-width: 1200px) {
  .mainSection-bannerContainer {
    position: absolute;
  }
}

@media only screen and (max-width: 992px) {
  .mainSection-button {
    margin-top: 20px;
  }
  .mainSection-bannerContainer {
    left: -32px;
  }
  .mainSection-banner {
    padding: 30px 80px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
  .mainSection-imageContainer {
    display: none;
  }
  .mainSection-banner {
    padding: 25px 80px;
  }

  .mainSection-bannerContainer {
  }
  .servicesSection {
    margin-top: 300px !important;
  }

  .row > * {
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .mainSection-banner {
    flex-direction: column;
    padding: 20px 30px;
  }
  .mainSection-banner > div:nth-child(1) {
    border: 0;
  }
  .mainSection-bannerHeading {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .mainSection-bannerContainer {
    left: 0px;
  }
}
