.locationcard-container {
  padding: 30px;
  box-shadow: 0 29px 36px rgba(0, 0, 0, 0.07), 0 11px 11px rgba(0, 0, 0, 0.04),
    0 2px 3px rgba(0, 0, 0, 0.02);

  border-radius: 4px;
  height: 100%;
}

.locationcard-phone {
  color: var(--grey);
}
