.sectioncard-container {
  padding: 30px;
  position: relative;
  box-shadow: 0 29px 36px rgba(0, 0, 0, 0.07), 0 11px 11px rgba(0, 0, 0, 0.04),
    0 2px 3px rgba(0, 0, 0, 0.02);

  border-radius: 4px;
  height: 100%;
  margin-top: 10px !important;
  z-index: 1000;
  background-color: white;
}

.sectioncard-heading {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.sectioncard-iconContainer {
  position: relative;

  left: -35px;

  height: 80px;
  width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
  font-size: 2.2rem;
  padding: 20px;
  background-color: var(--red);
}

.sectioncard-content {
  margin-top: 20px;
  color: var(--grey);
}
