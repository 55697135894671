.reviewcard-container {
  padding: 30px;
  box-shadow: 0 29px 36px rgba(0, 0, 0, 0.07), 0 11px 11px rgba(0, 0, 0, 0.04),
    0 2px 3px rgba(0, 0, 0, 0.02);
  height: 100%;
  padding-bottom: 50px;
  border-radius: 4px;
  padding-left: 0;
  position: relative;
  background-color: white;
}

.reviewcard-container > div {
  height: 100%;
}

.reviewcard-contentContainer {
  position: relative;
}

.reviewcard-author {
  position: absolute;
  bottom: -30px;
}
